import {
  ForecastCmuColumn,
  forecastColumnFactory,
  ForecastDriverColumn,
  ForecastOutputColumn
} from "./ForecastColumn";
import {isBlank, isPresent, qSortArray} from "../../helpers/common";
import { ForecastTimePeriod, ForecastTimeScale } from "./ForecastTImeScale";
import {FACTS_HEADER, ROW_DRIVER_ID_KEY, SUB_FACT_HEADER} from "../../forecast_simulator_scenario/helpers/ag_grid_vars";

export class ForecastConfig {
  constructor(config) {
    this.id = config.data.id;
    this.attributes = config.data.attributes;
    if (config.included) {
      this._initColumns(config.included)
      this._initTimeScales(config.included)
    }
  }

  get cmuHeaders() {
    return this.cmuColumns || []
  }

  get rootCmuHeaders() {
    return this.cmuColumns.sort((a, b) => a.sortOrder - b.sortOrder).slice(0, -1)
  }

  get rootCmuHeader() {
    return this.cmuColumns.sort((a, b) => a.sortOrder - b.sortOrder)[0];
  }

  viewDriversBy(timeScale) {
    return this.allFactsColumns.filter(column => column.visibleFor(timeScale))
  }

  // TODO: implement setting editable flag during initialization instead of checking it here
  isEditableDriver(timeScale, driverName) {
    return this.allFactsColumns.some(column => {
      return column.displayName === driverName && column.editableFor(timeScale)
    })
  }

  periodOptions(timeScale) {
    return this.getTimeScaleBy(timeScale)?.timePeriods?.map((periodData) =>
      ({
        value: periodData.start_date,
        start_date: periodData.start_date,
        end_date: periodData.end_date,
        label: periodData.optionLabel
      })
    ) || []
  }

  get firstForecastedPeriod() {
    if (isBlank(this._firstForecastedPeriod)) {
      this._firstForecastedPeriod = this.timeScales.flatMap(scale => scale.timePeriods).find(period => period.attributes.first_forecasted)
    }
    return this._firstForecastedPeriod;
  }

  getTimeScaleBy(key) {
    return this.timeScales?.find(scale => scale.key === key);
  }

  allIntersectedPeriodsIdsByPeriod(period) {
    return this.timeScales.flatMap(scale => scale.timePeriods).filter(timePeriod => {
      return timePeriod.startDate <= period.endDate && timePeriod.endDate >= period.startDate;
    }).map(period => period.id);
  }

  get dataTimeScale() {
    return this.timeScales.find(scale => scale.isInitData)
  }

  get timeScalesOptions() {
    return this.timeScales.map(timeScale =>
      ({ value: timeScale.key, label: timeScale.displayName })
    )
  }

  _initColumns(included) {
    const allColumns = included.filter(({ type }) => type === 'col').map(hash => forecastColumnFactory(hash));
    const cmuValues = included.filter(({ type }) => type === 'cmu')
    this.cmuColumns = qSortArray(
      allColumns.filter(column => column instanceof ForecastCmuColumn), true,
      column => column.sortOrder
    )
    this.cmuColumns.forEach(cmuColumn => cmuColumn.fillValues(cmuValues))
    this.outputColumns = qSortArray(
      allColumns.filter(column => column instanceof ForecastOutputColumn), true,
      column => column.sortOrder
    )
    this.driversColumns = qSortArray(
      allColumns.filter(column => column instanceof ForecastDriverColumn), true,
      column => column.sortOrder
    )
    this.factsCForChartsolumns = qSortArray(
      [
        ...this.outputColumns,
        ...this.driversColumns.filter(column => column.isViewInTable)
      ],
      true, column => column.sortOrder
    )
    this.allFactsColumns = qSortArray(
      [
        ...this.outputColumns,
        ...this.driversColumns.filter(column => column.isViewInTable),
        ...this.driversColumns.map(column => column.coefficientColumn).filter(isPresent)
      ],
      true, column => column.sortOrder
    )
  }

  _initTimeScales(included) {
    const timePeriodsData = included.filter(({ type }) => type === 'tp').map(hash => new ForecastTimePeriod(hash));
    this.timeScales = included.filter(({ type }) => type === 'ts').map(hash => new ForecastTimeScale(hash));
    this.timeScales.forEach(timeScale => timeScale.fillPeriods(timePeriodsData))
  }
}
