import { isBlank } from "../../helpers/common";
import { groupRowsByCmus } from "./ForecastScenarioRow";

const IGNORE_AGGREGATION = ['N/A', 'none', 'do not aggregate at all']
export const AGGREGATION_TYPES = {
  by_time: 'aggregate_within',
  by_cmu: 'aggregate_sub_totals'
};

const periodValues = (rows, driver) => rows.map(row => ({ value: driver.fetchValue(row) }))
const periodImpacts = (rows, driver, metric) => rows.map(row => ({
  value: driver.fetchImpact(row, metric)
}))
const valueForCallback = (rows, config) => (driverName) => {
  const relatedDriver = config.allFactsColumns.find(col => col.name === driverName)
  return periodValues(rows, relatedDriver).map(h => h.value)
}
const aggregatedValueForCallback = (rows, period, config, func) => (driverName) => {
  const relatedDriver = config.allFactsColumns.find(col => col.name === driverName)
  return func(rows, relatedDriver, period, config).value
}

export const ignoreAggregation = (aggregationType) => isBlank(aggregationType) || IGNORE_AGGREGATION.includes(aggregationType)

const sumValues = (values) => ({ value: values.reduce((acc, value) => acc + parseFloat(value), 0), format: true })
const valuesAvg = (values) => ({ value: values.reduce((acc, value) => acc + parseFloat(value), 0) / values.length, format: true })

const aggregatedRatio = (numerator, denominator, hashes, aggregatedValueFor) => {
  const aggregatedDemo = aggregatedValueFor(denominator, hashes)
  if (aggregatedDemo === 0) return { value: 0, format: true };

  const aggregatedNumerator = aggregatedValueFor(numerator, hashes)
  return {
    value: aggregatedNumerator / aggregatedDemo,
    format: true,
    aggregatedValueFor: {
      [denominator]: aggregatedDemo,
      [numerator]: aggregatedNumerator
    }
  }
}

const weightedSum = (values) => {
  const sumDriverOnValues = values.reduce((acc, value) => acc + parseFloat(value), 0)
  return {
    value: sumDriverOnValues,
    format: true,
    valuesFor: {}
  }
}

const weightedOnDriver = (driver, valuesFor, hashes, values) => {
  const driver_values = valuesFor(driver, hashes)
  const weighSumDriver = values.reduce((acc, value, index) => acc + (parseFloat(value) * parseFloat(driver_values[index])), 0)
  const sum = driver_values.reduce((acc, value) => acc + parseFloat(value), 0)
  return {
    value: (weighSumDriver / sum),
    format: true,
    valuesFor: {
      [driver]: sum
    }
  }
}



const aggregatedValue = (hashes, aggregationType, { aggregatedValueFor, valuesFor }) => {
  const values = hashes.map(h => h.value);
  if(values.length === 1) return { value: values[0], format: true }

  if (ignoreAggregation(aggregationType)) { return { value: 'do not aggregate', format: false } }
  switch(aggregationType) {
    case 'sum':
      return sumValues(values)
    case 'Average':
    case 'avg':
      return valuesAvg(values)
    case 'max':
      return { value: Math.max(...values), format: true }
    case 'min':
      return { value: Math.min(...values), format: true }
    case 'Ratio(Volume Sales,Unit Sales)':
      return aggregatedRatio('Volume Sales', 'Unit Sales', hashes, aggregatedValueFor)
    case 'Value Sales / Volume Sales':
    case 'Ratio(Value Sales,Volume Sales)':
      return aggregatedRatio('Value Sales', 'Volume Sales', hashes, aggregatedValueFor)
    case 'Value Sales / Unit Sales':
    case 'Ratio(Value Sales,Unit Sales)':
      return aggregatedRatio('Value Sales', 'Unit Sales', hashes, aggregatedValueFor)
    case 'Sum abs impact and weighted on Volume Sales':
    case 'Sum abs impact and weighted on Value Sales':
    case 'Sum weighted by units':
    case 'Sum weighted by volume':
    case 'Sum weighted by volumes':
    case 'Sum weighted by values':
      return weightedSum(values)
    case 'Weighted on(Distribution)':
      return weightedOnDriver('Total Points of Distribution', valuesFor, hashes, values)
    case 'Weighted on(Value)':
    case 'Weighted on Value':
      return weightedOnDriver('Value Sales', valuesFor, hashes, values)
    case 'Weighted on(Volume)':
    case 'Weighted on Volume':
    case 'Weighted on Volumes':
      return weightedOnDriver('Volume Sales', valuesFor, hashes, values)
    case 'Weighted on(Units)':
    case 'Weighted on Units':
      return weightedOnDriver('Unit Sales', valuesFor, hashes, values)
    default:
      return { value: `Unsupported for now : ${aggregationType}`, format: false }
  }
}

export const aggregatedByTime = (rows, driver, period, config)  => {
  const driverRules = driver.driverRules;
  const timeScaleConfig = config.timeScales.find(ts => ts.key === period.timeScaleKey)
  const aggregationType = timeScaleConfig.isInitData ? 'sum' : driverRules[AGGREGATION_TYPES.by_time][period.timeScaleKey];
  const hashes = periodValues(rows, driver);
  return aggregatedValue(
    hashes, aggregationType, {
      aggregatedValueFor: aggregatedValueForCallback(rows, period, config, aggregatedByTime),
      valuesFor: valueForCallback(rows, config)
    }
  )
}
export const aggregatedByCmu = (rows, driver, period, config, values = false) => {
  const driverRules = driver.driverRules;
  const timeScaleConfig = config.timeScales.find(ts => ts.key === period.timeScaleKey)
  const aggregationType = timeScaleConfig.isInitData ? 'sum' : driverRules[AGGREGATION_TYPES.by_cmu][period.timeScaleKey];
  const hashes = values || periodValues(rows, driver);
  return aggregatedValue(
    hashes, aggregationType, {
      aggregatedValueFor: aggregatedValueForCallback(rows, period, config, aggregatedByCmu),
      valuesFor: valueForCallback(rows, config)
    }
  )
}

export const combinedAggregation = (rows, driver, period, config) => {
  const driverRules = driver.driverRules;
  const timeScaleConfig = config.timeScales.find(ts => ts.key === period.timeScaleKey)
  const aggregationTypeByTime = timeScaleConfig.isInitData ? 'sum' : driverRules[AGGREGATION_TYPES.by_time][period.timeScaleKey];
  const aggregationTypeByCmu = timeScaleConfig.isInitData ? 'sum' : driverRules[AGGREGATION_TYPES.by_cmu][period.timeScaleKey];
  if (aggregationTypeByTime === aggregationTypeByCmu) {
    const hashes = periodValues(rows, driver);
    return aggregatedValue(
      hashes, aggregationTypeByTime, {
        aggregatedValueFor: aggregatedValueForCallback(rows, period, config, combinedAggregation),
        valuesFor: valueForCallback(rows, config)
      }
    )
  } else {
    const groupedByTime = Object.values(groupRowsByCmus(rows)).map(rows => {
      return {
        ...aggregatedByTime(rows, driver, period, config),
        rows
      }
    });
    return aggregatedValue(
      groupedByTime, aggregationTypeByCmu, {
        aggregatedValueFor: (driverName, hashes) => {
          const subAggregationType = config.allFactsColumns.find(col => col.name === driverName).driverRules[AGGREGATION_TYPES.by_cmu][period.timeScaleKey]
          const values = hashes.map(h => ({ value: h.aggregatedValueFor[driverName]  }) )
          return aggregatedValue(values, subAggregationType, { aggregatedValueFor: () => 0.0, valuesFor: () => 0.0 }).value
        },
        valuesFor: (driverName, hashes) => hashes.map(h => h[driverName])
      }
    )
  }
}

export const impactsAggregation = (rows, driver, period, config, metric) => {
  const rules = driver.decompRules[metric.id];
  const aggregationType = fetchImpactAggregationRule(rules, period.timeScaleKey);
  const hashes = periodImpacts(rows, driver, metric);

  return aggregatedValue(
    hashes, aggregationType, {
      aggregatedValueFor: () => 0.0,
      valuesFor: (_driverName) => periodValues(rows, metric).map(h => h.value)
    }
  );
}

const fetchImpactAggregationRule = (rules, timeScale) => {
  return rules.aggregate[timeScale] || rules.aggregate.default || Object.values(rules.aggregate)[0];
}

